import React, { ReactElement, useState } from 'react';
import { Footer } from 'shared-library';
import { useMutation } from '@apollo/client';
import { INSERT_LEAD } from '../apollo/mutations';

const FooterContainer = (): ReactElement => {
  const [leadCreationSuccess, setLeadCreationSuccess] = useState(false);
  const [insertLeadMutation] = useMutation(INSERT_LEAD);

  const handleCreateLead = async (formInput) => {
    try {
      const response = await insertLeadMutation({
        variables: {
          leadInput: {
            email: formInput.email,
            campaign: 'WEB_Footer',
            country: '',
            firstname: formInput.firstName,
            lastname: formInput.lastName,
            leadSource: '',
            leadType: 'Member',
            status: 'New',
            company: 'RemoveMe',
          },
        },
        context: {
          clientName: 'pii_public',
        },
      });

      if (response) {
        setLeadCreationSuccess(true);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return <Footer onSubmit={handleCreateLead} submitSuccessful={leadCreationSuccess} />;
};

export default FooterContainer;
